import * as React from "react";
import {
  Typography,
  Box,
  IconButton,
  Grid,
  Button as MUIButton
} from "@mui/material";
import { connect } from "react-redux";
import {
  NavLink,
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  MainInput,
  centered_flex_box,
  left_flex_box,
  main_button,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Button, Image, Space, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getTransactions } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";

export const Transactions = ({
  getTransactions,
  user,
  transactions,
  isLoading
}) => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = React.useState(moment().startOf("month"))
  const [endDate, setEndDate] = React.useState(moment().endOf("month"))

  useEffect(() => {
    getTransactions({})
  }, [])

  const handleFilter = () => {
    getTransactions({ startDate, endDate });
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
    },
    {
      title: 'Date Created',
      dataIndex: 'date',
      sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
    },
    {
      title: 'Date Paid',
      dataIndex: 'datePaid',
      sorter: (a, b) => { return moment(a.datePaid).isBefore(b.datePaid) ? -1 : 1 },
    },
    {
      title: 'Value',
      dataIndex: 'value',
    },
    {
      title: 'Chainz Fees',
      dataIndex: 'fees',
    },
    {
      title: 'Payout',
      dataIndex: 'payout',
    },
    {
      title: 'Order',
      dataIndex: 'order',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: "Pending",
          value: "Pending"
        },
        {
          text: "Received",
          value: "Received"
        },
        {
          text: "Paid",
          value: "Paid"
        }
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, { status }) => <Tag color={status === "Paid" ? "green" : status === "Received" ? "blue" : "yellow"}>{status.toUpperCase()}</Tag>
    },
  ];

  const data = transactions?.map((row) => {
    return {
      _id: row._id,
      date: moment(row.createdAt).format("lll"),
      datePaid: row.paidAt ? moment(row.paidAt).format("lll") : null,
      value: `${row.value} EGP`,
      fees: `${row.fees} EGP`,
      payout: `${row.value - row.fees} EGP`,
      status: row.status,
      order: <NavLink to={`/orders/${row.order._id}`}>{row.order._id}</NavLink>,
    }
  })

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Transactions</Typography>
          {/* <IconButton onClick={() => navigate("/ims/admin/products/add")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton> */}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", px: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography>From</Typography>
              <MainInput
                margin="normal"
                fullWidth
                type="date"
                value={startDate.format("YYYY-MM-DD")}
                onChange={({ target }) => setStartDate(moment(target.value))}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography>To</Typography>
              <MainInput
                margin="normal"
                fullWidth
                type="date"
                value={endDate.format("YYYY-MM-DD")}
                onChange={({ target }) => setEndDate(moment(target.value))}
              />
            </Grid>
            <Grid item xs={2} sx={{ ...left_flex_box, alignItems: "end" }}>
              <MUIButton sx={{ ...main_button, mb: 2 }} onClick={() => handleFilter()}>Filter</MUIButton>
            </Grid>
          </Grid>
        </Box>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  transactions: state?.records?.transactions
});

const mapDispatchToProps = { getTransactions };

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
