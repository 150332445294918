import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InfoIcon from '@mui/icons-material/Info';
import ContactsIcon from '@mui/icons-material/Contacts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DraftsIcon from '@mui/icons-material/Drafts';
import BoltIcon from '@mui/icons-material/Bolt';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import { connect } from "react-redux";
import { logout } from '../store/actions/authActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Chip, Drawer, Grid, InputAdornment, InputLabel, NativeSelect, Select, Tooltip, styled } from '@mui/material';
import { MainInput, MainSearch, centered_flex_box, left_flex_box, right_flex_box } from './Styles';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import StarsIcon from '@mui/icons-material/Stars';
import Logo from "./logo.png"
import Logo2 from "./logo2.png"
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { AccountCircleOutlined, Analytics, ArrowForward, AttachMoney, Autorenew, Checkroom, ChevronLeft, Close, ExpandMore, Favorite, History, Home, Inventory, Key, KeyboardReturn, LocalShipping, Message, MonetizationOn, People, PriceCheck, QuestionAnswer, QuestionMark, RequestQuote, Search, Send, Storage, Store, Warehouse } from '@mui/icons-material';
import LoginModal from './LoginModal';
import ImageComponent from './ImageComponent';

const drawerWidth = "15vw";

const DesktopAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    height: "10vh",
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `85vw`,
        marginLeft: `${drawerWidth}`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: `${drawerWidth}`,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    height: "10vh",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function TopBar({ auth, logout, children }) {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [loginOpen, setLoginOpen] = useState(false);
    const [search, setSearch] = useState(null);

    const user = auth?.user;
    const token = auth?.token || '';
    const header = token.split(' ')
    const role = header[0]
    const location = useLocation();

    const handleMenu = (event) => {
        setDrawerOpen(true)
    };

    const handleClose = () => {
        setDrawerOpen(false)
    };

    const handleLogin = () => {
        setDrawerOpen(false);
        route("/login")
    };

    const handleLogout = () => {
        setDrawerOpen(false);
        logout();
    };

    const handleHome = () => {
        navigate("/");
    };

    const handleProfile = () => {
        let path = "/" + role + "/profile";
        navigate(path);
    };

    const route = (path) => {
        navigate(path);
    }


    const menuItems = <>
        <MobileView>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <IconButton className='position-absolute top-0 end-0' onClick={handleClose}><Close /></IconButton>
                <ImageComponent preview={false} src={Logo} height={100} width={140} />
                <Typography fontWeight={"bold"} color={"var(--secColor)"}>Partner</Typography>
            </Box>
        </MobileView>
        <BrowserView>
            <DrawerHeader className="position-relative">
                <Box className='position-absolute top-50 start-50 translate-middle'>
                    <ImageComponent preview={false} src={Logo} height={100} width={140} />
                </Box>
                <IconButton className='position-absolute top-50 end-0 translate-middle-y' onClick={handleClose}>
                    <ChevronLeft />
                </IconButton>
            </DrawerHeader>
        </BrowserView>
        {user && <>
            <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/")}><HomeIcon /><Typography marginX={2}>Dashboard</Typography></MenuItem>
            <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/products")}><Inventory /><Typography marginX={2}>Products</Typography></MenuItem>
            <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/inventory/send")}><Send /><Typography marginX={2}>Send Inventory</Typography></MenuItem>
            <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/inventory/history")}><History /><Typography marginX={2}>Inventory History</Typography></MenuItem>
            <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/orders")}><LocalShipping /><Typography marginX={2}>Orders</Typography></MenuItem>
            <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/returns")}><KeyboardReturn /><Typography marginX={2}>Returns</Typography></MenuItem>
            <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/exchanges")}><Autorenew /><Typography marginX={2}>Exchanges</Typography></MenuItem>
            <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/finance")}><AttachMoney /><Typography marginX={2}>Finance</Typography></MenuItem>
            <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/keys")}><Key /><Typography marginX={2}>Shopify Intergration</Typography></MenuItem>
        </>}
        <hr />
        <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/about")}><InfoIcon /><Typography marginX={2}>About Us</Typography></MenuItem>
        <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/contact")}><Message /><Typography marginX={2}>Contact Us</Typography></MenuItem>
        <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => route("/faq")}><QuestionAnswer /><Typography marginX={2}>FAQs</Typography></MenuItem>
        <hr />
        {user && role === "Partner" && <MenuItem onClick={handleLogout}><LogoutIcon color='error' /><Typography color='error' marginX={2}>Logout</Typography></MenuItem>}
        {!user && <MenuItem onClick={handleLogin} sx={{ color: "var(--secColor)" }}><LoginIcon /><Typography marginX={2}>Login</Typography></MenuItem>}

    </>


    return (
        <Box sx={{ color: "var(--secColor)", paddingBottom: 9 }}>
            <BrowserView>
                <DesktopAppBar sx={{ marginBottom: 2 }} position="fixed" open={drawerOpen} color='white'>
                    <Toolbar sx={{ margin: 1 }}>
                        <Grid container>
                            <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                {!drawerOpen && <>
                                    <IconButton onClick={handleMenu}><MenuIcon sx={{ color: "var(--secColor)" }} /></IconButton>
                                    <ImageComponent preview={false} src={Logo} height="80px" onClick={handleHome} style={{ cursor: "pointer" }} />
                                    <Typography fontWeight={"bold"} color={"var(--secColor)"}>Partner</Typography>
                                </>}
                            </Grid>
                            <Grid item xs={6} sx={{ ...centered_flex_box }}>

                            </Grid>
                            <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                {user && <IconButton onClick={() => route("/user/account")}><AccountCircle sx={{ color: "var(--secColor)" }} /></IconButton>}
                                {!user && <IconButton onClick={() => handleLogin()}><AccountCircleOutlined sx={{ color: "var(--secColor)" }} /></IconButton>}
                            </Grid>
                        </Grid>
                    </Toolbar >
                    {/* <React.Fragment key={"right"}>
                        <Drawer
                            anchor={"right"}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <Box sx={{ width: "25vw", padding: 1 }}>
                                {menuItems}
                            </Box>
                        </Drawer>
                    </React.Fragment> */}
                </DesktopAppBar >
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={drawerOpen}
                >
                    <Box sx={{ padding: 1 }}>
                        {menuItems}
                    </Box>
                </Drawer>
                <Main open={drawerOpen}>
                    {children}
                </Main>
            </BrowserView>
            <MobileView>
                <AppBar sx={{ marginBottom: 2 }} position="fixed" color='primary'>
                    <Toolbar>
                        <Grid container>
                            <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <ImageComponent width={50} height={"auto"} preview={false} src={Logo2} onClick={handleHome} style={{ cursor: "pointer", padding: 5 }} />
                            </Grid>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                {!user && <IconButton onClick={() => handleLogin()}><AccountCircleOutlined sx={{ color: "var(--appBg)" }} /></IconButton>}
                                <IconButton onClick={handleMenu}><MenuIcon sx={{ color: "var(--appBg)" }} /></IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar >
                    <React.Fragment key={"top"}>
                        <Drawer
                            anchor="top"
                            open={drawerOpen}
                            onClose={handleClose}
                        >
                            <Box sx={{ padding: 2 }}>
                                {menuItems}
                            </Box>
                        </Drawer>
                    </React.Fragment>
                </AppBar >
                {children}
            </MobileView>
        </Box>
    );
}

const mapStateToProps = (state) => ({
    auth: state?.auth
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);