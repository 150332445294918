import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  IconButton,
  TableContainer,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  FormControl,
  Tabs,
  Tab,
  Card,
  CardContent,
  CardHeader,
  Divider
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainPassword,
  main_button,
  StyledInput,
  MainPhone,
  sec_button,
  reject_button
} from "../app/components/Styles";
import { getOrders } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline, Download } from "@mui/icons-material";
import moment from "moment";

export const Order = ({ getOrders, user, order, isLoading }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getOrders({ orderId: id })
  }, [id])

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>{order?.isReturn ? "Return " : ""}Order for "{order?.vendor.name}"</Typography>
        </Box>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Card sx={{ width: "100%" }}>
            <CardContent sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                {/* <Button sx={{ ...main_button, mr: 2 }} disabled={order?.status !== "New"}>Edit</Button> */}
                {!order?.isReturn && <Button sx={{ ...main_button, mr: 2 }} disabled={order?.status !== "Delivered"} onClick={() => navigate(`/orders/return/${id}`)}>Start Return</Button>}
                {!order?.isReturn && <Button sx={{ ...main_button, mr: 2 }} disabled={order?.status !== "Delivered"}>Start Exchange</Button>}
              </Box>
              <Box>
                {/* <Button disabled={order?.status !== "New"} sx={{ ...reject_button, mr: 2 }}>Cancel</Button> */}
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Typography>Order {id}</Typography>
                <Typography>Created at {moment(order?.createdAt).format("lll")}</Typography>
                <Typography fontWeight="bold">Status: {order?.status}</Typography>
                {order?.assignedAt && <Typography>Assigned to employee at {moment(order?.assignedAt).format("lll")}</Typography>}
                {order?.preparedAt && <Typography>Packed at {moment(order?.preparedAt).format("lll")}</Typography>}
                {order?.delivered && <Typography>Delivered at {moment(order?.delivered).format("lll")}</Typography>}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <Card>
              <CardContent>
                <Typography variant="h5" textAlign="center">Shipping Details</Typography>
                <Divider />
                {order?.orderFulfillment?.shippingAddress?.name && <Typography>Name: {order?.orderFulfillment?.shippingAddress?.name}</Typography>}
                {order?.orderFulfillment?.shippingAddress?.phone && <Typography>Phone Number: <NavLink to={`tel:"${order?.orderFulfillment?.shippingAddress?.phone}`}>{order?.orderFulfillment?.shippingAddress?.phone}</NavLink></Typography>}
                {order?.orderFulfillment?.shippingAddress?.address1 && <Typography>Address 1: {order?.orderFulfillment?.shippingAddress?.address1}</Typography>}
                {order?.orderFulfillment?.shippingAddress?.address2 && <Typography>Address 2: {order?.orderFulfillment?.shippingAddress?.address2}</Typography>}
                {order?.orderFulfillment?.price && <Typography fontWeight={"bold"}>Total Price: {order?.orderFulfillment?.price} EGP</Typography>}
              </CardContent>
            </Card>
            <Card sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="h5" textAlign="center">Items</Typography>
                <Divider />
                <Box sx={{ p: 2 }}>
                  {order?.orderItems && order?.orderItems.length > 0 && order?.orderItems.map((item) => {
                    return (
                      <Card key={item._id} sx={{ width: "100%", mb: 1 }}>
                        <CardContent sx={{ ...centered_flex_box, flexDirection: "column", width: "100%" }}>
                          <Avatar src={item.stockItem.product.image} />
                          <Typography fontWeight={"bold"} textAlign={"center"}>{item.stockItem.product.name}</Typography>
                          <Typography>{item.stockItem.product.externalId}</Typography>
                          <hr />
                          {item.stockItem.product.size && <Typography>Size: {item.stockItem.product.size}</Typography>}
                          {item.stockItem.product.weight && <Typography>Weight: {item.stockItem.product.weight}</Typography>}
                          {item.stockItem.product.color && <Typography>Color: {item.stockItem.product.color}</Typography>}
                          <hr />
                          <Typography fontWeight={"bold"}>Quantity: {item.quantityOrdered}</Typography>
                        </CardContent>
                      </Card>
                    )
                  })}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  order: state?.records?.order
});

const mapDispatchToProps = { getOrders };

export default connect(mapStateToProps, mapDispatchToProps)(Order);
