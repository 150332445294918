import * as React from 'react';
import { Typography, Box, Container, Card, Button, TextField, CssBaseline, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, CardContent, Divider, IconButton } from '@mui/material';
import { connect } from "react-redux";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, left_flex_box, main_button, right_flex_box, sec_button } from '../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Image } from 'antd';
import { AdminPanelSettingsOutlined, ArrowForward, Dashboard, RequestPageOutlined, VisibilityOutlined } from '@mui/icons-material';
import { getDashboard } from '../app/store/actions/dataActions';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment/moment';

export const Home = ({ user, getDashboard, dashboard }) => {

    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(moment().startOf("month"))
    const [endDate, setEndDate] = useState(moment().endOf("month"))

    useEffect(() => {
        getDashboard({ startDate, endDate });
    }, [])

    const handleFilter = () => {
        getDashboard({ startDate, endDate });
    }

    return (
        <Container sx={{ minWidth: isMobile ? "100% !important" : "90% !important" }}>
            <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                <Grid container p={5}>
                    <Grid item xs={isMobile ? 12 : 8}>
                        <Typography fontWeight={"bold"} variant={isMobile ? 'h4' : 'h2'}> Dashboard</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", px: 5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <Typography>From</Typography>
                        <MainInput
                            margin="normal"
                            fullWidth
                            type="date"
                            value={startDate.format("YYYY-MM-DD")}
                            onChange={({ target }) => setStartDate(moment(target.value))}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Typography>To</Typography>
                        <MainInput
                            margin="normal"
                            fullWidth
                            type="date"
                            value={endDate.format("YYYY-MM-DD")}
                            onChange={({ target }) => setEndDate(moment(target.value))}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{ ...left_flex_box, alignItems: "end" }}>
                        <Button sx={{ ...main_button, mb: 2 }} onClick={() => handleFilter()}>Filter</Button>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={3} p={5}>
                <Grid item xs={isMobile ? 12 : 2}>
                    <Card m={3}>
                        <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography fontSize={14} fontWeight={"bold"}>Received Orders</Typography>
                        </CardContent>
                        <CardContent>
                            <Typography fontSize={18}>{dashboard?.orders.totalOrders} Orders</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={isMobile ? 12 : 2}>
                    <Card m={3}>
                        <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography fontSize={14} fontWeight={"bold"}>Fulfilled Orders</Typography>
                        </CardContent>
                        <CardContent>
                            <Typography fontSize={18}>{dashboard?.orders.totalFulfilled} Orders</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={isMobile ? 12 : 2}>
                    <Card m={3}>
                        <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography fontSize={14} fontWeight={"bold"}>Delivered Orders</Typography>
                        </CardContent>
                        <CardContent>
                            <Typography fontSize={18}>{dashboard?.orders.totalDelivered} Orders</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={isMobile ? 12 : 2}>
                    <Card m={3}>
                        <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography fontSize={14} fontWeight={"bold"}>Cancelled Orders</Typography>
                        </CardContent>
                        <CardContent>
                            <Typography fontSize={18}>{dashboard?.orders.totalCancelled} Orders</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={isMobile ? 12 : 2}>
                    <Card m={3}>
                        <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography fontSize={14} fontWeight={"bold"}>Received COD</Typography>
                        </CardContent>
                        <CardContent>
                            <Typography fontSize={18}>{dashboard?.cod.totalIncome} EGP</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={isMobile ? 12 : 2}>
                    <Card m={3}>
                        <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography fontSize={14} fontWeight={"bold"}>Total Payout</Typography>
                        </CardContent>
                        <CardContent>
                            <Typography fontSize={18}>{dashboard?.cod.totalPayout} EGP</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item xs={isMobile ? 12 : 2}>
                    <Card m={3}>
                        <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography fontSize={14} fontWeight={"bold"}>Total Fees</Typography>
                        </CardContent>
                        <CardContent>
                            <Typography fontSize={18}>{dashboard?.cod.totalFees} EGP</Typography>
                        </CardContent>
                    </Card>
                </Grid> */}
            </Grid>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    dashboard: state?.records?.dashboard
});

const mapDispatchToProps = { getDashboard };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
