import * as React from "react";
import {
  Typography,
  Box,
  IconButton,
  Snackbar,
  Button as MaterialButton
} from "@mui/material";
import { connect } from "react-redux";
import {
  NavLink,
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  centered_flex_box, main_button,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Button, Image, Space, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getKeys, addKey } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline, CopyAll } from "@mui/icons-material";
import moment from "moment";
import copy from "clipboard-copy";
import { useState } from "react";

export const Keys = ({
  getKeys,
  addKey,
  user,
  apiKey,
  isLoading
}) => {
  const navigate = useNavigate();
  const [snackBar, setSnackBar] = useState(false);

  const handleCopy = (link) => {
    copy(link);
    setSnackBar(true);
  };

  useEffect(() => {
    getKeys({})
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Key',
      dataIndex: 'value',
      render: (_, { value }) => <Space>{value} <IconButton onClick={() => handleCopy(value)}><CopyAll /></IconButton></Space>
    },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (_, { active }) => <Tag color={active ? "green" : "red"}>{active ? "ACTIVE" : "INACTIVE"}</Tag>
    }
  ];

  const data = [{
    _id: apiKey?._id,
    name: apiKey?.name,
    value: apiKey?.value,
    active: apiKey?.active,
  }]

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Shopify Integration</Typography>
          <IconButton onClick={() => addKey({})} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton>
        </Box>
        <Table
          columns={columns}
          dataSource={data}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
        />
        <Box>
          <Typography variant="h5" fontWeight={"bold"}>Integration Instructions</Typography>
          <Typography gutterBottom={15}>1. Install the CHAINZ Shopify App from the <NavLink className={"a2"}>Shopify App Store</NavLink></Typography>
          <Typography gutterBottom={15}>2. After installing the app, you will find the input for the API Key in the home page, add the API key shown at the top of this page. {apiKey && <NavLink onClick={() => handleCopy(apiKey.value)}>Copy Key</NavLink>}</Typography>
          <Typography gutterBottom={15}>3. From the "Sync Products" page, select all product you would like to fulfill with CHAINZ and press "Send to CHAINZ".</Typography>
          <Typography gutterBottom={15}>4. Congratulations! All orders placed on your store are automatically sent to your CHAINZ account. CHAINZ will also handle your inventory automatically. No action is required from your side to sync orders.</Typography>

        </Box>
        <Snackbar
          open={snackBar}
          autoHideDuration={3000}
          onClose={() => setSnackBar(false)}
          message="Key Copied"
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  apiKey: state?.records?.key
});

const mapDispatchToProps = { getKeys, addKey };

export default connect(mapStateToProps, mapDispatchToProps)(Keys);
